import {Component, OnDestroy} from "@angular/core";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {ReCaptchaV3Service} from "ng-recaptcha";
import {ReplaySubject, switchMap, takeUntil} from "rxjs";
import {ApiService} from "src/app/services/api.service";
import {environment} from "src/environments/environment";

@Component({
  selector: "app-newsletter",
  templateUrl: "./newsletter.component.html",
  styleUrls: ["./newsletter.component.scss"],
})
export class NewsletterComponent implements OnDestroy {
  emailFormGroup = new FormGroup({
    email: new FormControl("", {nonNullable: true, validators: [Validators.required, Validators.email]}),
  });

  sending!: boolean;
  success!: boolean;
  hasError!: boolean;

  unsubscribe$ = new ReplaySubject(1);

  constructor(private apiService: ApiService, private recapatchaV3Service: ReCaptchaV3Service) {}

  ngOnDestroy(): void {
    this.unsubscribe$.next(1);
    this.unsubscribe$.complete();
  }

  get email() {
    return this.emailFormGroup.controls["email"];
  }

  addToNewslettersList() {
    this.sending = true;
    this.success = this.hasError = false;
    this.recapatchaV3Service
      .execute(environment.recaptcha.newslettersAction)
      .pipe(
        takeUntil(this.unsubscribe$),
        switchMap((token) => {
          return this.apiService.submitAction(environment.recaptcha.newslettersAction, token, {email: this.email.value});
        }),
      )
      .subscribe({
        next: () => {
          this.sending = false;
          this.success = true;
        },
        error: () => {
          this.sending = false;
          this.hasError = true;
        },
      });
  }
}
