import {AfterViewInit, Component, ElementRef, Input, OnDestroy, ViewChild} from "@angular/core";
import {Carousel} from "bootstrap";
import {CarouselSlide} from "src/app/interfaces/carousel-slide";

@Component({
  selector: "app-carousel",
  templateUrl: "./carousel.component.html",
  styleUrls: ["./carousel.component.scss"],
})
export class CarouselComponent implements AfterViewInit, OnDestroy {
  @Input() slides!: CarouselSlide[];

  @ViewChild("carousel") carouselElement!: ElementRef<HTMLDivElement>;

  carousel!: Carousel;

  ngAfterViewInit(): void {
    if (!this.carousel) {
      this.carousel = new Carousel(this.carouselElement.nativeElement, {
        interval: 5000,
        ride: true,
        touch: true,
      });
    }
  }

  ngOnDestroy(): void {
    if (this.carousel) {
      this.carousel.dispose();
    }
  }

  getPaddedIndex(index: number) {
    return String(index + 1).padStart(2, "0");
  }
}
