import {Component} from "@angular/core";
import {environment} from "src/environments/environment";

@Component({
  selector: "app-copyright",
  templateUrl: "./copyright.component.html",
  styleUrls: ["./copyright.component.scss"],
})
export class CopyrightComponent {
  currentYear = new Date().getFullYear();
  baseURL = environment.baseURL;
}
