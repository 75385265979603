import {gql} from "apollo-angular";
import {NavbarItem} from "src/app/interfaces/navbar-item";

export const GET_NAVBAR_ITEMS = gql<{departments: NavbarItem[]; services: NavbarItem[]}, void>`
  query {
    departments(filter: {status: {_eq: "published"}}, sort: ["order"]) {
      link: id
      title: name
    }
    services(filter: {status: {_eq: "published"}}, sort: ["date_created"]) {
      link: url
      title: name
    }
  }
`;
