import {NgModule} from "@angular/core";
import {RouterModule, Routes} from "@angular/router";

import {PublishingLandingPageComponent} from "./components/publishing/pages/publishing-landing-page/publishing-landing-page.component";
import {PublishingLandingPageResolver} from "./resolvers/publishing/publishing-landing-page.resolver";

const routes: Routes = [
  {path: "", component: PublishingLandingPageComponent, resolve: {page: PublishingLandingPageResolver}},
  {path: "**", redirectTo: ""},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {anchorScrolling: "enabled", onSameUrlNavigation: "reload"})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
