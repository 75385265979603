import {gql} from "apollo-angular";
import {CarouselSlide} from "src/app/interfaces/carousel-slide";
import {
  PublishingLandingPage,
  PublishingLandingPageRegulation,
  PublishingLandingPageSubject,
} from "src/app/interfaces/publishing/publishing-landing-page";

export const GET_PUBLISHING_LANDING_PAGE_DATA = gql<
  {
    page: PublishingLandingPage;
    carousel: CarouselSlide[];
    regulations: PublishingLandingPageRegulation[];
    subjects: PublishingLandingPageSubject[];
  },
  null
>`
  query {
    page: publishing_home_page {
      aboutSectionTitle: about_section_title
      aboutSectionSubtitle: about_section_subtitle
      aboutSectionSummary: about_section_summary
      aboutSectionImage: about_section_image {
        id
        filename: filename_download
        type
      }
      regulationsSectionTitle: regulations_section_title
      ctaSectionTitle: cta_title
      ctaSectionContent: cta_content
      ctaSectionImage: cta_image {
        id
        filename: filename_download
        type
      }
      applicationFormPdf: application_form_pdf {
        id
        filename: filename_download
        type
      }
      acknowledgementPdf: acknowledgement_pdf {
        id
        filename: filename_download
        type
      }
      subjectsSectionTitle: subjects_section_title
      subjectsSectionSubtitle: subjects_section_subtitle
    }
    carousel: publishing_home_slider(filter: {status: {_eq: "published"}}, sort: ["date_created"]) {
      title
      subtitle
      image {
        id
        filename: filename_download
        type
      }
    }
    regulations: publishing_regulations(filter: {status: {_eq: "published"}}, sort: ["date_created"]) {
      title
      content
    }
    subjects: publishing_subjects(filter: {status: {_eq: "published"}}, sort: ["date_created"]) {
      title
      image {
        id
        filename: filename_download
        type
      }
    }
  }
`;
