<div id="home-carousel" #carousel class="carousel slide carousel-fade" data-bs-ride="false">
  <div class="carousel-inner">
    <div *ngFor="let slide of slides; let first = first" class="carousel-item" [ngClass]="{active: first}">
      <img [src]="slide.image | imageLink" class="d-block w-100 h-100 cover" [alt]="slide.title" />
      <div class="carousel-caption text-start">
        <p style="font-size: var(--home-main-slider-subTitle-fontSize)" class="lead fw-bold">{{ slide.title }}</p>
        <h5 style="font-size: var(--home-main-slider-title-fontSize)" class="h1 fw-bold">{{ slide.subtitle }}</h5>
      </div>
    </div>
  </div>

  <ol *ngIf="slides && slides.length > 1" class="carousel-indicators">
    <li
      *ngFor="let slide of slides; let index = index; let first = first"
      [attr.data-index]="getPaddedIndex(index)"
      data-bs-target="#home-carousel"
      [attr.data-bs-slide-to]="index"
      [ngClass]="{active: first}"
      aria-current="true"
      [attr.aria-label]="slide.title"
    ></li>
  </ol>
  <button
    *ngIf="slides && slides.length > 1"
    class="carousel-control-prev"
    type="button"
    data-bs-target="#home-carousel"
    data-bs-slide="prev"
  >
    <i class="fa-solid fa-angle-right fs-1"></i>
    <span class="visually-hidden">Previous</span>
  </button>
  <button
    *ngIf="slides && slides.length > 1"
    class="carousel-control-next"
    type="button"
    data-bs-target="#home-carousel"
    data-bs-slide="next"
  >
    <i class="fa-solid fa-angle-left fs-1"></i>
    <span class="visually-hidden">Next</span>
  </button>
</div>
