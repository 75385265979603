import {Component, Input} from "@angular/core";
import {Hero} from "src/app/interfaces/hero";
import {ImageLinkPipe} from "src/app/pipes/image-link.pipe";

@Component({
  selector: "app-hero",
  templateUrl: "./hero.component.html",
  styleUrls: ["./hero.component.scss"],
})
export class HeroComponent {
  @Input() data!: Hero;
  @Input() showTitle = true;
  @Input() showSubtitle = true;
  @Input() backgroundOverlay = true;

  constructor(private imageLinkPipe: ImageLinkPipe) {}

  get imageURL() {
    if (!this.data) {
      return "";
    }
    return this.imageLinkPipe.transform(this.data.image);
  }
}
