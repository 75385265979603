<div id="newsletter" class="bg-primary py-5">
  <div class="container">
    <h5 class="text-light text-center fw-bold">
      <i class="fa-solid fa-envelope-open-text pb-3 me-2"></i>
      النشرة البريدية - جسور
    </h5>
    <p class="text-light text-center">
      ابق على تواصل مع المكتب من خلال الاشتراك في النشرة البريدية ( جسور ) ليصلك جميع المستجدات في مجال التربية والتعليم
    </p>

    <div class="row">
      <form class="col-xl-4 col-lg-5 col-md-6 col-12 mx-auto" [formGroup]="emailFormGroup">
        <div class="input-group mt-3">
          <input
            type="email"
            required
            formControlName="email"
            class="form-control small focus-shadow-none"
            [ngClass]="{'is-invalid': email.invalid && (email.dirty || email.touched)}"
            placeholder="اشترك بتسجيل بريدك الإلكتروني"
          />
          <span class="input-group-text p-0 border-0">
            <button
              [disabled]="email.invalid || sending || success || hasError"
              (click)="addToNewslettersList()"
              class="btn py-3 px-4 rounded-0 rounded-end"
              [ngClass]="{'btn-danger': hasError, 'btn-dark': !hasError && !success, 'btn-success': success}"
              style="min-width: 100px"
            >
              <span *ngIf="!sending && !success && !hasError">اشتراك</span>
              <span *ngIf="sending" class="fade-in-down"><i class="fa-solid fa-circle-notch fa-spin ms-1"></i></span>
              <span *ngIf="!sending && success" class="fade-in-down"><i class="fa-solid fa-check"></i></span>
              <span *ngIf="!sending && hasError" class="fade-in-down"><i class="fa-solid fa-triangle-exclamation"></i></span>
            </button>
          </span>
        </div>
      </form>
    </div>
  </div>
</div>
