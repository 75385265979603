import {Component, OnInit} from "@angular/core";
import {Title} from "@angular/platform-browser";
import {ActivatedRoute} from "@angular/router";
import {Breadcrumb} from "src/app/interfaces/breadcrumb";
import {Hero} from "src/app/interfaces/hero";
import {PageData} from "src/app/interfaces/page-data";

@Component({
  selector: "app-page",
  templateUrl: "./page.component.html",
  styleUrls: ["./page.component.scss"],
})
export class PageComponent implements OnInit {
  title!: string;
  hero!: Hero;
  breadcrumbs!: Breadcrumb[];
  hideHeroTitle!: boolean;
  hideHeroSubtitle!: boolean;
  hideFontShareSection!: boolean;
  hideBreadcrumbs!: boolean;
  heroBackgroundOverlay!: boolean;

  constructor(private titleService: Title, private route: ActivatedRoute) {}

  ngOnInit(): void {
    const {title, hero, breadcrumbs, options} = this.route.snapshot.data["page"] as PageData;
    this.title = title;
    this.hero = hero as Hero;
    this.breadcrumbs = breadcrumbs as Breadcrumb[];
    this.hideBreadcrumbs = !!options?.hideBreadcrumbs;
    this.hideHeroTitle = !!options?.hideHeroTitle;
    this.hideHeroSubtitle = !!options?.hideHeroSubtitle;
    this.hideFontShareSection = !!options?.hideFontShareSection;
    this.heroBackgroundOverlay = !!options?.heroBackgroundOverlay;

    if (this.title) {
      this.titleService.setTitle(`${this.title} | مكتب التربية العربي لدول الخليج`);
    } else {
      this.titleService.setTitle(`مكتب التربية العربي لدول الخليج`);
    }
  }
}
