<ng-content select="page-header"></ng-content>

<app-hero
  [data]="hero"
  [showTitle]="!hideHeroTitle"
  [showSubtitle]="!hideHeroSubtitle"
  [backgroundOverlay]="heroBackgroundOverlay"
></app-hero>

<app-breadcrumb *ngIf="!hideBreadcrumbs" [data]="breadcrumbs"></app-breadcrumb>

<main class="page-content world-bg pt-4 pb-5">
  <ng-content select="page-content"></ng-content>
  <app-fonts-share *ngIf="!hideFontShareSection"></app-fonts-share>
</main>

<ng-content select="page-footer"></ng-content>
