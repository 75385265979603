import {Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild} from "@angular/core";
import {ReplaySubject, takeUntil} from "rxjs";
import {ContactUsPage} from "src/app/interfaces/portal/contact-us-page";
import {PortalContentService} from "src/app/services/portal-content.service";
import {environment} from "src/environments/environment";

@Component({
  selector: "app-footer-links",
  templateUrl: "./footer-links.component.html",
  styleUrls: ["./footer-links.component.scss"],
})
export class FooterLinksComponent implements OnInit, OnDestroy {
  SCROLL_OFFSET = 300;

  contact!: ContactUsPage;

  @ViewChild("scrollTopBtn") scrollTopButtonRef!: ElementRef<HTMLButtonElement>;
  baseURL = environment.baseURL;
  unsubscribe$ = new ReplaySubject(1);

  footerLinks = [
    {
      title: "من نحن",
      href: `${this.baseURL}/about`,
    },
    {
      title: "الرؤية الرسالة و الأهداف",
      href: `${this.baseURL}/vision-mission`,
    },
    {
      title: "الخطط الإستراتيجية",
      href: `${this.baseURL}/strategic-plans`,
    },
    {
      title: "معالي المدير العام",
      href: `${this.baseURL}/manager`,
    },
    {
      title: "المراكز",
      href: `${this.baseURL}/centers`,
    },
    {
      title: "مهام المكتب",
      href: `${this.baseURL}/tasks`,
    },
    {
      title: "المشاريع والبرامج",
      href: `${this.baseURL}/projects`,
    },
    {
      title: "اللوائح والقوانين",
      href: `${this.baseURL}/laws`,
    },
    {
      title: "الإدارات",
      href: `${this.baseURL}/departments`,
    },
    {
      title: "الاتفاقيات",
      href: `${this.baseURL}/agreements`,
    },
    {
      title: "الدول الأعضاء",
      href: `${this.baseURL}/members`,
    },

    {
      title: "المكتب في أرقام",
      href: `${this.baseURL}/numbers`,
    },
    {
      title: "المكانة الإقليمية والدولية",
      href: `${this.baseURL}/standing`,
    },
    {
      title: "الفعاليات",
      href: `${this.baseURL}/events`,
    },
    {
      title: "الندوات",
      href: `${this.baseURL}/conferences`,
    },
    {
      title: "الدورات",
      href: `${this.baseURL}/training`,
    },
    {
      title: "الورش",
      href: `${this.baseURL}/workshops`,
    },
    {
      title: "الندوات",
      href: `${this.baseURL}/seminars`,
    },
    {
      title: "اللقاءات",
      href: `${this.baseURL}/meetings`,
    },
    {
      title: "الإصدارات",
      href: `${this.baseURL}/publications`,
    },
    {
      title: "الدراسات",
      href: `${this.baseURL}/studies`,
    },
    {
      title: "التقارير",
      href: `${this.baseURL}/reports`,
    },
    {
      title: "الأخبار",
      href: `${this.baseURL}/news`,
    },
    {
      title: "معرض الصور",
      href: `${this.baseURL}/photo-gallery`,
    },
    {
      title: "معرض الفيديو",
      href: `${this.baseURL}/video-gallery`,
    },
    {
      title: "إنفوجرافيك",
      href: `${this.baseURL}/infographic-gallery`,
    },
    {
      title: "تواصل معنا",
      href: `${this.baseURL}/contact-us`,
    },
  ];
  constructor(private cmsService: PortalContentService) {}

  ngOnInit() {
    this.fetchContactUsDetails();
  }

  @HostListener("window:scroll")
  onWindowScroll() {
    if (!this.scrollTopButtonRef) return;

    if (window.scrollY > this.SCROLL_OFFSET) {
      this.scrollTopButtonRef.nativeElement.classList.add("shown");
    } else {
      this.scrollTopButtonRef.nativeElement.classList.remove("shown");
    }
  }

  fetchContactUsDetails() {
    this.cmsService
      .getContactUsDetails()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (result) => {
          this.contact = result;
        },
      });
  }
  ngOnDestroy(): void {
    this.unsubscribe$.next(1);
    this.unsubscribe$.complete();
  }
}
